<template>
  <div class="lg:flex lg:space-x-10">
    <div class="space-y-7 w-full">
      <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ forum.title }}</h1>

      <div class="breadcrumb-area py-0 flex items-center justify-content-between">
        <div class="breadcrumb">
          <ul class="m-0">
            <li>
              <router-link title="Diễn đàn" :to="{name: 'ForumIndex'}">
                Diễn đàn
              </router-link>
            </li>
            <li v-if="forum.parent">
              <router-link :title="forum.parent.title"
                           :to="{name: 'ForumDetail', params: {id: forum.parent.id, slug: forum.parent.slug}}">
                {{ forum.parent.title }}
              </router-link>
            </li>
            <li class="active" v-if="forum.slug">
              <router-link :title="forum.title" :to="{name: 'ForumDetail', params: {id: forum.id, slug: forum.slug}}">
                {{ forum.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <router-link :to="{name: 'DiscussionAdd', query: {forum_id: id}}"
                     class="flex items-center justify-center px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 hover:text-white text-white space-x-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"></path>
          </svg>
          <span class="whitespace-nowrap">Tạo chủ đề</span>
        </router-link>
      </div>

      <table v-if="discussions.data && discussions.data.length" class="w-full">
        <tbody>
        <discussion-item :discussion="discussion" :index="index + 1"
                             v-for="(discussion, index) in discussions.data" :key="'discussion' + index"/>
        </tbody>
      </table>

      <div v-else class="bg-yellow-500 border p-4 relative rounded-md uk-alert">
        <p class="text-white">Chuyên mục này chưa có chủ đề nào. Mời bạn tạo chủ đề!</p>
      </div>

      <div class="mt-4">
        <v-pagination
            class="justify-content-center"
            v-if="discussions.paginatorInfo.lastPage > 1"
            v-model="discussions.paginatorInfo.currentPage"
            :pages="discussions.paginatorInfo.lastPage"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="loadForum"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import DiscussionItem from "./DiscussionItem";

export default {
  name: 'ForumDetail',
  components: {
    VPagination,
    DiscussionItem
  },
  data() {
    return {
      id: null,
      forum: {
        title: "",
        slug: ""
      },
      discussions: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadForum() {
      let query = `query($id: ID!) {
        forum(id: $id) {
          id
          title
          slug
          parent {
            id
            title
            slug
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.forum) {
              this.forum = data.data.forum;
              let key = "discussions";
              if (!this.forum.parent) {
                key = "discussionsThrough";
              }
              this.loadDiscussions(key);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadDiscussions(key) {
      let query = `query($id: ID!, $page: Int) {
        forum(id: $id) {
          ${key}(first: 10, page: $page, orderBy: [{ column: "created_at", order: DESC }], where: {AND: [{column: "status", value: 1}]}) {
            data {
              id
              slug
              title
              content
              views
              created_at
              comment_count
              thumbnail {
                url
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id, page: this.discussions.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.forum && data.data.forum[key]) {
              this.discussions = data.data.forum[key];
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadForum();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadForum();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
