<template>
  <tr>
    <td class="px-2 py-2 text-emerald-600 font-medium flex items-center space-x-2">
      <router-link class="font-semibold"
                   :to="{name: 'DiscussionDetail', params: {id: discussion.id, slug: discussion.slug}}">
        {{ discussion.title }}
      </router-link>
      <div class="flex items-center space-x-2">
        <ion-icon name="chatbubble-ellipses-outline" class="text-lg md hydrated" role="img"
                  aria-label="chatbubble ellipses outline"></ion-icon>
        <span> {{ numberFormat(discussion.comment_count) }} </span>
        <ion-icon name="eye-outline" class="text-lg md hydrated" role="img" aria-label="ios eye outline"></ion-icon>
        <span> {{ numberFormat(discussion.views) }} </span>
      </div>
    </td>
  </tr>
</template>

<script>

import {numberFormat} from "../../core/services/utils.service";

export default {
  name: "DiscussionItemBox",
  props: {
    index: Number,
    discussion: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  methods: {
    numberFormat(x) {
      return numberFormat(x);
    }
  }
}
</script>
